import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../slices/auth/authSlice';
import subAdminReducer from '../slices/subAdmin/SubAdminSlice';
import parkAdminReducer from '../slices/parkAdmin/parkAdminSlice';
import areaReducer from '../slices/areas/areaSlice';
import driverReducer from '../slices/driver/driverSlice';
import manifestReducer from '../slices/manifest/manifestSlice';
import vehicleReducer from '../slices/vehicle/vehicleSlice';
import locationReducer from '../slices/location/locationSlice';


export const store = configureStore({
  reducer: {
    auth: authReducer, 
    subAdmins: subAdminReducer,
    areas: areaReducer,               
    parkAdmins: parkAdminReducer,               
    drivers: driverReducer,               
    manifests: manifestReducer,               
    vehicles: vehicleReducer,               
    locations: locationReducer,               
  },
});

export type AppDispatch = typeof store.dispatch

import axios from 'axios'

const API_URL = process.env.REACT_APP_URL || ' '


const getAllLocations = async (token: String) => {
  
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
    const response = await axios.get(API_URL + 'users/areas-parks-admin',config)
    return response.data
  }


const locationService = {
    getAllLocations,
  }


  export default locationService
import { useEffect, useState } from 'react'
import {  useSelector,useDispatch } from 'react-redux'
import axios from "axios";
import {Link, useNavigate, useParams} from "react-router-dom"
import { toast } from 'react-toastify'
import { getAllAreas, reset } from '../slices/areas/areaSlice'


function User() {


  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {subAdmins, isLoading, message} = useSelector((state) => state.subAdmins)
  const {user} = useSelector((state) => state.auth)
  const {areas} = useSelector((state) => state.areas)
  const {subAdminId} = useParams();
  const subAdmin = subAdmins.find((subAdmin) => subAdmin.id === subAdminId)

  const [formData, setFormData] = useState({
    // areaID: '',
    areaID: areas && areas.length > 0 ? areas[0].id : '',
    // areaID: 'a3a8f200-92b1-4121-bb5f-ff30a74325d3',
  })
  const areaID = formData
  
  const [file, setFile] = useState();
  const [image, setImage] = useState()
  const token = user.access_token
  console.log("This user:" + subAdmin);

  const {phoneNumber, nin, fullName, gender, age, email, area, homeAddress, selfieFile, id} = subAdmin

  // console.log(id)
  const baseURL = `https://api.travelreg.com.ng/users/${id}/upload-selfie`
  const selfie = `https://api.travelreg.com.ng/users/${id}/download-selfie`

  const updateAgenAreatUrl = `https://api.travelreg.com.ng/users/${id}/sub-admin-area`
  
  // const deleteProductUrl = `https://dev-store.boosta.ng/store-product/delete-product/${id}`

  function handleChange(e) {
    console.log(e.target.files);
    setFile(e.target.files[0]);
    setImage(URL.createObjectURL(e.target.files[0]))    
}

useEffect(() => {
  dispatch(getAllAreas())
  // console.log(areas)
}, [dispatch])


const onChange = (e) => {
  e.preventDefault()
  const { name, value } = e.target;
  setFormData((prevState) => ({
    ...prevState,
    [name]: value,
  }))
  console.log(formData)
}



const config = {
  headers: { 
    Authorization: `Bearer ${token}`,
    "Content-Type": "multipart/form-data"
  }
 };

const onSubmit = async (e) => {
  e.preventDefault()
  const formData = new FormData();
  formData.append('file', file);
   try {   
    await axios.post(baseURL, formData, config)
    console.log(formData)
    toast.success("Image has been uploaded")
    navigate('/dashboard/sub')

  } catch (e){
    toast.error(e)
  }

}

const handleAreaAssignment  =  async (e) => {
    
  e.preventDefault()
  
  const config = {
    headers: { Authorization: `Bearer ${token}` }
   };

  try {
    console.log(formData)
    const res = await axios.put(updateAgenAreatUrl, formData, config)
    toast.success("Agent has been Assigned Succesfully")
    navigate('/dashboard/sub')

  } catch (e){
    toast.error(e)
  }
}

// const onDeleteClick = async (e) => {
//   try {   
//     await axios.delete(deleteProductUrl, config)
//     toast.success("Product has been deleted")
//     navigate('/dashboard/inventory')
//   } catch (e){
//     toast.error("The product can not be deleted, most likely it is already stocked.")
//   }
// }  

  return (
    <div className="relative z-0 h-screen overflow-hidden bg-trans-back">
    <div className='mt-6'>
    <Link to='/dashboard/sub' className='rounded-md border m-4 px-2 py-2 bg-white'>Back </Link>
    {/* <div onClick={onDeleteClick} className='rounded-md border m-4 text-red-500 px-2 cursor-pointer w-32 py-2 bg-white'>Delete Product </div> */}
        <main className="relative z-0 overflow-y-auto focus:outline-none mt-4 grid grid-cols-2">
          {/* Start main area*/}
          <div className=' mx-6 bg-white p-4 rounded-2xl grid space-y-4 text-indigo-600'>
          <h1>Full Name: {fullName}</h1>
          <h1> Phone Number: {phoneNumber}</h1>
          <h1>NIN: {nin}</h1>
          <h1> Age: {age}</h1>
          <h1> Home Address: {homeAddress}</h1>
          <h1> Email: {email} </h1>
          <h1> Area: {area? area.title:""} </h1>

          <h1>Created At: {new Date(subAdmin.createdAt).toLocaleString('en-US')}</h1>
          {!subAdmin.area && (
      <form action="#" onSubmit={handleAreaAssignment }>
        <div className="space-y-3 my-4">
                        <label htmlFor="categoryId" className="block text-sm font-medium text-gray-700">
                          Assign Area to this Sub-Admin
                        </label>
                        <div className="mt-1">
                          <select name="areaID" id="areaID"  value={formData.areaID} onChange={onChange}
                          required 
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          >
                          {areas ? areas.map(area => (
                            <option value={area.id} key={area.id}>{area.title}</option>
                          )) :  <option value="Car">Null</option>}
                        
                          </select>
                          
                        </div>
                      </div>



                      <div className='my-2'>
                        <button
                          type="submit"
                          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Save Area
                        </button>
                      </div>
                  
        </form>
          )}
        
          {file ? <img src={image} alt=" " /> : null}

          </div>
          {!subAdmin.selfieFile &&
          <div className='grid'>
          <form action="#" onSubmit={onSubmit}  className="space-y-6">
                  <div className='grid grid-cols-2  mx-4'>
                  <div className='mx-4'>
                  <label htmlFor="files" className="flex text-sm font-medium text-gray-700">
                  Image to be uploaded
                 </label>
                 <div className="mt-1">
                   <input
                     id="file"
                     name="file"
                     type="file"
                     required
                     accept="image/png, image/jpeg, image/jpg"
                     onChange={(e)=> handleChange(e)}
                     placeholder="Image"
                     className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                   />
                 </div>
                 
                      <div className='my-2'>
                        <button
                          type="submit"
                          className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                          Upload Image
                        </button>
                      </div>
                  </div>
                  <div className='mx-4'>
                  
              
                  </div>
            </div>
          </form>
          </div>
          }


{subAdmin.selfieFile &&
         <div className=" relative mt-1 py-2 h-500 w-full">
         <img src={selfie} alt="Map" className="inset-0 w-full h-full object-cover" style={{ maxHeight: '400px'}} />
       </div>

          }


         
         

        </main>

    </div>
  
</div>
  )
}

export default User
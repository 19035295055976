import React, { useState } from 'react';
import { Menu } from '@headlessui/react';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TableHead  from '@mui/material/TableHead';
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePaginationActions from "../components/TablePaginationActions";
import TablePagination from "@mui/material/TablePagination";
import {
    MagnifyingGlassIcon,
    ChevronDownIcon
  } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'
import CreateSubAdmin from '../components/modals/CreateSubAdmin';
import {useNavigate} from "react-router-dom"
import {  useSelector } from 'react-redux'
import { getAllSubAdmins } from '../slices/subAdmin/SubAdminSlice';
import { logout,reset } from "../slices/auth/authSlice"
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Spinner from 'react-spinkit'
import { getAllDrivers } from '../slices/driver/driverSlice';
import { getAllParkAdmins } from '../slices/parkAdmin/parkAdminSlice';

function Driver () {

  const navigate = useNavigate()
  const [modal, setModal] = useState(false)

  const {subAdmins} = useSelector(
    (state) => state.subAdmins)
    const subAdminsCount = subAdmins.length

    const {parkAdmins} = useSelector(
      (state) => state.parkAdmins)
      const totalParkAdmins = parkAdmins.length

  const {drivers, isLoading, isSuccess} = useSelector(
    (state) => state.drivers)
    const driversCount = drivers.length
    const {user} = useSelector((state) => state.auth)
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);


    const userP = {
        name: user.fullName,
        imageUrl:  `https://api.travelreg.com.ng/users/${user.id}/download-selfie`
         // Replace with the actual image URL
      };
    
      // Function to handle logout
      const handleLogout = () => {
        dispatch(logout())
        dispatch(reset())
        navigate('/')
        console.log("Logging out")
      };
      const [isOpen, setIsOpen] = useState(false);

      const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - drivers.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

      const dispatch = useDispatch()

    
      
        useEffect(() => {
          dispatch(getAllDrivers())
        }, [dispatch])
      
        useEffect(() => {
          dispatch(getAllSubAdmins())
        }, [dispatch])
      
        useEffect(() => {
          dispatch(getAllParkAdmins())
        }, [dispatch])
      
      const onClick = () => {
        setModal(!modal)
      }
  

      if (isLoading) {
        return (
        <div className="grid place-items-center h-full w-full">
        <Spinner 
        name="ball-spin-fade-loader"
        color="blue"
        fadeIn="none"
        className="flex-col pt-32 grid place-items-center items-center  absolute top-1/2 justify-center  h-screen"
        />
        </div>
        )
      }
  
      
  return (
    <div className="bg-gray-100 h-screen">
        <div className="bg-white flex justify-between items-center px-6 py-4">
            <div>
                {/* <p>cgfgffvff</p> */}
                <form className="flex w-full lg:ml-0" action="#" method="GET">
                          <label htmlFor="search-field" className="sr-only">
                            Search
                          </label>
                          <div className="relative w-full mx-auto left-6 mt-2  text-gray-400 focus-within:text-gray-600">
                            <div className="pointer-events-none absolute inset-y-0  left-2 flex items-center">
                              <MagnifyingGlassIcon className="h-6 w-6 pl-2" aria-hidden="true" />
                            </div>
                            <input
                              id="search-field"
                              className="block h-full w-30 border-gray rounded-lg py-2 pl-8  placeholder-gray-400 focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-0 sm:text-sm px-4 bg-white-100 text-white"
                              placeholder="Search"
                              type="text"
                              name="search"
                            />
                          </div>
                          </form>
            </div>
            <div className="relative">
        <Menu>
          {({ open }) => (
            <>
              <Menu.Button className="flex items-center">
                <img
                  className="h-8 w-8 rounded-full"
                  src={userP.imageUrl}
                  alt={userP.name}
                />
                <span className="ml-2 text-gray-800 cursor-pointer">{userP.name}</span>
                <ChevronDownIcon className="h-5 w-5 ml-1 text-gray-400" />
              </Menu.Button>
              <Menu.Items className={`absolute right-0 mt-2 w-48 bg-white shadow-lg rounded-md ${open ? 'block' : 'hidden'}`}>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`w-full py-2 px-4 text-sm text-gray-800 ${active ? 'bg-gray-100' : ''}`}
                      onClick={handleLogout}
                    >
                      Logout
                    </button>
                  )}
                </Menu.Item>
                {/* Add more Menu.Item components for additional options */}
              </Menu.Items>
            </>
          )}
        </Menu>
      </div>      
      </div>

      <div className="flex justify-between items-center px-6 py-4">
        <div>
          <p>Staff</p>
        </div>
        <div className="relative">
        {/* <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none"> */}
                         {/*  <button
                              onClick={onClick}
                              type="button"
                              className="inline-flex items-center justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:w-auto"
                            >
                              Create New Sub Admin
                          </button>
                    {modal && ( <CreateSubAdmin />) } */}
        </div>
      </div>
      <div className="flex space-x-4">
          <span><Link
                              to='/dashboard/sub'                                                                                                     
                              className="inline-flex items-center justify-center rounded-md border ml-1 border-transparent bg-gray-100 px-2 py-2 text-sm font-medium text-blue shadow-sm hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2 sm:w-auto"
                            >
                              Sub Admin<span>({subAdminsCount})</span>
                    </Link></span>
          <span><Link
                              to='/dashboard/park'                                                                                                     
                              className="inline-flex items-center justify-center rounded-md border ml-1 border-transparent bg-gray-100 px-2 py-2 text-sm font-medium text-blue shadow-sm hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2 sm:w-auto"
                            >
                              Park Admin <span>({totalParkAdmins})</span>
                    </Link></span>
          <span><Link
                              to='/dashboard/driver'                                                                                                     
                              className="inline-flex items-center justify-center rounded-md border ml-1 border-transparent bg-gray-100 px-2 py-2 text-sm font-medium text-blue shadow-sm hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-gray-300 focus:ring-offset-2 sm:w-auto"
                              style={{
                                outline: 'none',
                                borderColor: '#4F46E5',
                                boxShadow: '0 0 0 3px rgba(79, 70, 229, 0.3)'
                              }}
                            >
                              Driver<span>({driversCount}) </span>
                    </Link></span>
                   
        </div>
        <hr className="border-gray-400"/>
        {/* <div className="bg-white p-4"> */}
     <div className="mt-8 flex flex-col">
                  <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                      <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                      <TableContainer component={Paper}>
                        <Table className="min-w-full divide-y divide-gray-300">
                          <TableHead className="bg-gray-50">
                            <TableRow>
                              <TableCell  scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                               Driver Name & Id
                              </TableCell>
                              <TableCell  scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Bus
                              </TableCell>
                              <TableCell  scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                                Parks
                              </TableCell>
                              <TableCell  scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                              Phone Number
                            </TableCell>
                              <TableCell scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                                <p>{'    '}</p>
                              </TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody className="divide-y divide-gray-200 bg-white mb-4 pb-4">
                          
                            {(rowsPerPage > 0 ? drivers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : drivers ).map((driver) => (
                              <TableRow key={driver.id}>
                                <TableCell className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                  {driver.fullName}
                                  <p></p>
                                  <span>{driver.id}</span>
                                </TableCell>
                                <TableCell className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{driver.plateNumber ? driver.plateNumber : " "}</TableCell>
                                <TableCell className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{driver.park ? driver.park.title : " "}</TableCell>
                                <TableCell className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">{driver.phoneNumber}</TableCell>
                                {/* <TableCell className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                  <Link to={`/dashboard/sub/${subAdmin.id}`} className="text-indigo-600 hover:text-indigo-900 underline text-md">
                                 <p>View</p>
                                  </Link>
                                </TableCell>
                                */}
                                {/* <TableCell className="relative whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                  <Link to={`/dashboard/sub/${subAdmin.id}`} className="text-indigo-600 hover:text-indigo-900 underline text-md">
                                 <p>Update</p>
                                  </Link>
                                </TableCell> */}
                               
                              </TableRow>
                            ))}

                            {emptyRows > 0 && (
                              <TableRow style={{ height: 53 * emptyRows }}>
                                <TableCell colSpan={6} />
                              </TableRow>
                            )}

                          </TableBody>
                          <TableFooter>
                          <TableRow>
                            <TablePagination
                              rowsPerPageOptions={[5, 7, 8, { label: "All", value: -1 }]}
                              colSpan={3}
                              count={drivers.length}
                              rowsPerPage={rowsPerPage}
                              page={page}
                              SelectProps={{
                                inputProps: {
                                  "aria-label": "rows per page",
                                },
                                native: true,
                              }}
                              onPageChange={handleChangePage}
                              onRowsPerPageChange={handleChangeRowsPerPage}
                              ActionsComponent={TablePaginationActions}
                            />
                          </TableRow>
                        </TableFooter>
                        </Table>
                      </TableContainer>
               
                      </div>
                    </div>
                  </div>
                </div>
    </div>
    // </div>
  );
};

export default Driver;

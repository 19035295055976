import React from 'react';
import ResponsiveTable from './responsiveTable'; // Import the ResponsiveTable component

function LocationDetailsTable({ locations }) {
  console.log(locations)
  const columns = [
    {
      Header: 'Passenger',
      accessor: 'title',
    },
    {
      Header: 'Age',
      accessor: 'title',
    },
    // {
    //   Header: 'Sex',
    //   accessor: 'sex',
    // },
    // {
    //     Header: 'Marital Status',
    //     accessor: 'maritalStatus',
    //   },
    //   {
    //     Header: 'Phone Number',
    //     accessor: 'phoneNumber',
    //   },
    //   {
    //     Header: 'Address',
    //     accessor: 'address',
    //   },
    // {
    //   Header: 'Email',
    //   accessor: 'email',
    // },
    
    // {
    //   Header: 'N.O.K',
    //   accessor: 'nextOfKinPhoneNumber',
    // },
  ];

  // return <ResponsiveTable columns={columns} data={locations} />;
}

export default LocationDetailsTable;

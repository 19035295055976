/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { useDispatch, useSelector  } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Spinner from 'react-spinkit'
import { createSubAdmin ,getAllSubAdmins,reset} from '../../slices/subAdmin/SubAdminSlice'

export default function CreateSubAdmin() {
  
  const {isLoading, isSuccess, isError, message} = useSelector((state) => state.subAdmins)
  const [open, setOpen] = useState(true)
  const [formData, setFormData] = useState({
    fullName: '',
    phoneNumber: '',
    email:'',
    gender:'',
    age:'',
    homeAddress:'',
    nin:'',
    password:'',
    role:'',
    drivExperience:'',
    maritalStatus:'',
  })


  const dispatch = useDispatch()
  const navigate = useNavigate()

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
    // console.log(formData)

  } 

  const onSubmit = (e) => {
    e.preventDefault()
    const userData = {
      ...formData,
      role: 'Sub-Admin',
      drivExperience: 'Expert',
      maritalStatus: 'Married',
    }
    dispatch(createSubAdmin(userData))
  }


  useEffect(() => {
    if (isError) {
      if (message && typeof message === 'string') {
        toast.error(message);
      } else if (message && Array.isArray(message)) {
        toast.error(message[0]);
      } else {
        toast.error('An error occurred while processing your request.');
      }
      dispatch(reset());
    }

    if(isSuccess){
      dispatch(getAllSubAdmins());
      toast.success(message);
      navigate('/dashboard/sub');
      dispatch(reset());}

    }, [dispatch, isError, navigate, message])
  

  const { fullName, phoneNumber,email,age,homeAddress,nin,gender,drivExperience,password,maritalStatus} = formData

  // useEffect(() => {
  //   dispatch(getAllSubAdmins())
  // }, [dispatch])


  if (isLoading) {
    return (
    <div className="grid place-items-center h-full w-full">
    <Spinner 
    name="ball-spin-fade-loader"
    color="blue"
    fadeIn="none"
    className="flex-col pt-32 grid place-items-center items-center justify-center  h-screen"
    />
    </div>
    )
  }

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-sm sm:p-6">
                <div>
                  <div className="mt-3 text-left sm:mt-5">
                    <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-indigo-600">
                      Create Sub Admin
                    </Dialog.Title>
                    <div className="mt-3">
                  <form action="#" onSubmit={onSubmit} className="space-y-3">
                    <div>
                      <label htmlFor="fullName" className="block text-sm font-medium text-gray-700">
                        Full Name
                      </label>
                      <div className="mt-1">
                        <input
                          id="fullName"
                          name="fullName"
                          type="string"
                          autoComplete="fullName"
                          required
                          value={fullName}
                          onChange={onChange}
                          placeholder=""
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>
  
                    <div className="space-y-1">
                      <label htmlFor="phoneNumber" className="block text-sm font-medium text-gray-700">
                        Phone Number
                      </label>
                      <div className="mt-1">
                        <input
                          id="phoneNumber"
                          name="phoneNumber"
                          type="string"
                          value={phoneNumber}
                          onChange={onChange}
                          autoComplete=""
                          placeholder='Phone Number'
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                        Email Address
                      </label>
                      <div className="mt-1">
                        <input
                          id="email"
                          name="email"
                          type="string"
                          value={email}
                          onChange={onChange}
                          autoComplete=""
                          placeholder='Email Address'
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                        Password
                      </label>
                      <div className="mt-1">
                        <input
                          id="password"
                          name="password"
                          type="string"
                          value={password}
                          onChange={onChange}
                          autoComplete=""
                          placeholder='Password'
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="gender" className="block text-sm font-medium text-gray-700">
                        Gender
                      </label>
                      <div className="mt-1">
                        <select
                          id="gender"
                          name="gender"
                          type="string"
                          value={gender}
                          onChange={onChange}
                          autoComplete="gender"
                          placeholder='Gender'
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                       >
                        <option value="">Select Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                      </select>
                      </div>
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="age" className="block text-sm font-medium text-gray-700">
                        Age
                      </label>
                      <div className="mt-1">
                        <input
                          id="age"
                          name="age"
                          type="string"
                          value={age}
                          onChange={onChange}
                          autoComplete=""
                          placeholder='Age'
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="homeAddress" className="block text-sm font-medium text-gray-700">
                        Home Address
                      </label>
                      <div className="mt-1">
                        <input
                          id="homeAddress"
                          name="homeAddress"
                          type="string"
                          value={homeAddress}
                          onChange={onChange}
                          autoComplete=""
                          placeholder='Home Address'
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>
                    <div className="space-y-1">
                      <label htmlFor="nin" className="block text-sm font-medium text-gray-700">
                        NIN
                      </label>
                      <div className="mt-1">
                        <input
                          id="nin"
                          name="nin"
                          type="string"
                          value={nin}
                          onChange={onChange}
                          autoComplete=""
                          placeholder='Enter NIN'
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>
                    <div className="mt-5 sm:mt-6">
                    <button
                      type="submit"
                      className="inline-flex w-full justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:text-sm"
                    >
                      Create
                    </button>
                  </div>
                  </form>
                </div>
                  </div>
                </div>
            
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  )
}

import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import locationService from './locationService'

const initialState = {
    locations: [],
    location: {},
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
}

export const getAllLocations = createAsyncThunk(
    'locations/getAll',
    async (_, thunkAPI:any) => {
      try {
        const token = thunkAPI.getState().auth.user.access_token

        return await locationService.getAllLocations(token)
      } catch (error: any) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
  
        return thunkAPI.rejectWithValue(message)
      }
    }  
)




export const locationSlice = createSlice({
  name: 'driver',
  initialState,
  reducers: {
      reset: (state) => initialState
  },
  extraReducers: (builder) => {
      builder.addCase(getAllLocations.pending, (state) => {
          state.isLoading = true
        })
        .addCase(getAllLocations.fulfilled, (state, action) => {
          state.isLoading = false
          state.isSuccess = true
          state.locations = action.payload
        })
        .addCase(getAllLocations.rejected, (state, action: any) => {
          state.isLoading = false
          state.isError = true
          state.message = action.payload
        })
  },
})

export const {reset} = locationSlice.actions

export default locationSlice.reducer
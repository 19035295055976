import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import vehicleService from './vehicleService'

const initialState = {
    vehicles: [],
    vehicle: {},
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
}

export const getAllVehicles = createAsyncThunk(
    'vehicles/getAll',
    async (_, thunkAPI:any) => {
      try {
        const token = thunkAPI.getState().auth.user.access_token

        return await vehicleService.getAllVehicles(token)
      } catch (error: any) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
  
        return thunkAPI.rejectWithValue(message)
      }
    }  
)




export const vehicleSlice = createSlice({
  name: 'vehicle',
  initialState,
  reducers: {
      reset: (state) => initialState
  },
  extraReducers: (builder) => {
      builder.addCase(getAllVehicles.pending, (state) => {
          state.isLoading = true
        })
        .addCase(getAllVehicles.fulfilled, (state, action) => {
          state.isLoading = false
          state.isSuccess = true
          state.vehicles = action.payload
        })
        .addCase(getAllVehicles.rejected, (state, action: any) => {
          state.isLoading = false
          state.isError = true
          state.message = action.payload
        })
  },
})

export const {reset} = vehicleSlice.actions

export default vehicleSlice.reducer
import { useEffect, useState } from 'react'
import { useDispatch, useSelector  } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { login, reset } from '../slices/auth/authSlice'
import Spinner from "react-spinkit"
import { Link } from "react-router-dom"
import  logo from "../images/logo.svg"
import  loginImage from "../images/loginImage.svg"


export default function Login() {
  const [formData, setFormData] = useState({
    phoneNumber: '',
    password: '',
  })

  const { phoneNumber, password} = formData

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {user, isLoading, isSuccess, isError, message} = useSelector((state) => state.auth)

  
  useEffect(() => {
    if (isError) {
      toast.error(message)
    }

    // Redirect when logged in
    if (isSuccess || user) {
      navigate('/dashboard')
    }

    dispatch(reset())
  }, [isError, isSuccess, user, message, navigate, dispatch])

  const onChange = (e) => {
    setFormData((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }))
    console.log(formData)
  }

  const onSubmit = (e) => {
    e.preventDefault()
    const userData = {
      phoneNumber,
      password
    }
    console.log(userData)
    dispatch(login(userData))
  }

  if (isLoading) {
    return (
    <div className="grid place-items-center h-full w-full">
    <Spinner 
    name="ball-spin-fade-loader"
    color="blue"
    fadeIn="none"
    className="flex flex-col pt-32 text-center items-center justify-center  h-screen"
    />
    </div>
    )
  }

    return (
      <>
        <div className="min-h-full  flex">
          <div className="flex-1 flex flex-col justify-start py-2 px-4 sm:px-6 lg:flex-none lg:px-20 xl:px-24 bg-blue-100 w-1/2 h-screen">
            <div className="mx-10 w-full max-w-sm lg:w-100 mt-20">
              <div>
                <img
                  className="h-10 w-auto"
                  src={logo}
                  alt=""
                />
                <h2 className="mt-6 text-3xl font-extrabold text-blue-100">.</h2>
                <h2 className="mt-6 text-3xl font-extrabold text-gray-900">Login</h2>
                <p className="mt-2 text-sm text-gray-600">
                </p>
              </div>
  
              <div className="mt-4 mb-8">
                <div>
                  <div>
                    <p className="text-sm font-medium text-gray-700">Login to your account with your details</p>
                    <p className="text-sm font-medium text-blue-100">Login to your account with your details</p>
  
                  </div>

                </div>
  
                <div className="mt-4">
                  <form action="#" onSubmit={onSubmit} className="space-y-2">
                    <div>
                      <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                        Phone Number
                      </label>
                      <div className="mt-1">
                        <input
                          id="phoneNumber"
                          name="phoneNumber"
                          type="string"
                          autoComplete="phoneNumber"
                          required
                          value={phoneNumber}
                          onChange={onChange}
                          placeholder="Phone number"
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>
  
                    <div className="space-y-1">
                      <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                        Password
                      </label>
                      <div className="">
                        <input
                          id="password"
                          name="password"
                          type="password"
                          value={password}
                          onChange={onChange}
                          placeholder='password'
                          autoComplete="current-password"
                          required
                          className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                        />
                      </div>
                    </div>
  
                    <div className="flex items-center justify-between">
                  
  
                      <div className="text-sm ml-20">
                        <Link to="/" className=" ml-20 font-medium text-black-600 hover:text-indigo-500">
                         <span className='text-blue-100'>..............................</span> Forgot Password?
                        </Link>
                      </div>
                    </div>
  
                    <div>
                      <button
                        type="submit"
                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-blue-500 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
                      >
                        Login 
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          <div className="hidden lg:block relative w-100 flex-1 mt-20">
            <img
              className="ml-20 mt-15"
              src={loginImage}
              alt=""
            />
          </div>
        </div>
      </>
    )
  }
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import authService from './authService';
// import jwtDecode from 'jwt-decode';

// Get user from localstorage
const userCred: string | null = localStorage.getItem('user')

const initialState = {
    user: userCred ? JSON.parse(userCred) : null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
}


// Login user
export const login = createAsyncThunk('auth/signin', async (user, thunkAPI) => {
    try {
      return await authService.login(user)
    } catch (error: any) {
      const message =
        (error.response && error.response.data && error.response.data.message) ||
        error.message ||
        error.toString()
  
      return thunkAPI.rejectWithValue(message)
    }
  })

//get User details
// export const getUserDetails = createAsyncThunk('auth/details', async ( thunkAPI: any) => {
//   try {
//     const token = thunkAPI.getState().auth.user.token
//     const decoded: any = jwtDecode(token)
//     console.log(decoded)
//     const {userId} = decoded
//     return await authService.getLoggedInUser(userId, token)
//   } catch (error: any) {
//     const message =
//       (error.response && error.response.data && error.response.data.message) ||
//       error.message ||
//       error.toString()

//     return thunkAPI.rejectWithValue(message)
//   }
// })



// Logout user
export const logout = createAsyncThunk('auth/signout', async ():Promise<void> => {
    await authService.logout()
  })

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        reset: (state) => {
            state.isLoading = false;
            state.isError = false;
            state.isSuccess = false;
            state.message = ''
        }
    },
    extraReducers: (builder) => {
        builder.addCase(login.pending, (state) => {
            state.isLoading = true
          })
          .addCase(login.fulfilled, (state, action) => {
            state.isLoading = false
            state.isSuccess = true
            state.user = action.payload
          })
          .addCase(login.rejected, (state, action: any) => {
            state.isLoading = false
            state.isError = true
            state.message = action.payload
            state.user = null
          })
        .addCase(logout.fulfilled, (state) => {
            state.user = null
          })
    },
})

export const {reset} = authSlice.actions
export default authSlice.reducer
import axios from 'axios'

const API_URL = process.env.REACT_APP_URL || ' '

// Get user restaurants
const getAllParkAdmins = async (token: String) => {
  
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
    const response = await axios.get(API_URL + 'users/park-admins?skip=0&limit=10000',config)
    return response.data
  }


const ParkAdminService = {
  getAllParkAdmins,
  }


  export default ParkAdminService
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import areaService from './areaService'

const initialState = {
    areas: [],
    area: {},
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
}

export const getAllAreas = createAsyncThunk(
    'areas/getAll',
    async (_, thunkAPI) => {
      try {
        return await areaService.getAllAreas()
      } catch (error: any) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
  
        return thunkAPI.rejectWithValue(message)
      }
    }  
)


// Create new area
export const createArea = createAsyncThunk(
  'areas/create',
  async (areaData, thunkAPI: any) => {
    try {
      const token = thunkAPI.getState().auth.user.access_token
      return await areaService.createAnArea(areaData, token)
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()

      return thunkAPI.rejectWithValue(message)
    }
  }
)


// Create new area
export const deleteArea = createAsyncThunk(
  'areas/delete',
  async (areaId: any, thunkAPI: any) => {
    try {
      const token = thunkAPI.getState().auth.user.access_token
      console.log(token)
      return await areaService.deleteAnArea(areaId, token)
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()

      return thunkAPI.rejectWithValue(message)
    }
  }
)



export const areaSlice = createSlice({
  name: 'area',
  initialState,
  reducers: {
      reset: (state) => initialState
  },
  extraReducers: (builder) => {
      builder.addCase(getAllAreas.pending, (state) => {
          state.isLoading = true
        })
        .addCase(getAllAreas.fulfilled, (state, action) => {
          state.isLoading = false
          state.isSuccess = true
          state.areas = action.payload
        })
        .addCase(getAllAreas.rejected, (state, action: any) => {
          state.isLoading = false
          state.isError = true
          state.message = action.payload
        })
        .addCase(createArea.pending, (state) => {
          state.isLoading = true
        })
        .addCase(createArea.fulfilled, (state) => {
          state.isLoading = false
          state.isSuccess = true
        })
        .addCase(createArea.rejected, (state, action: any) => {
          state.isLoading = false
          state.isError = true
          state.message = action.payload
        })
        .addCase(deleteArea.pending, (state) => {
          state.isLoading = true
        })
        .addCase(deleteArea.fulfilled, (state) => {
          state.isLoading = false
          state.isSuccess = true
        })
        .addCase(deleteArea.rejected, (state, action: any) => {
          state.isLoading = false
          state.isError = true
          state.message = action.payload
        })
  },
})

export const {reset} = areaSlice.actions

export default areaSlice.reducer
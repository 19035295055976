import axios from 'axios'

const API_URL = process.env.REACT_APP_URL || ' '


const getAllDrivers = async (token: String) => {
  
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  } ;
  try{
    const response = await axios.get(API_URL + 'users/drivers?skip=0&limit=1000',config)
    return response.data}
    catch(error){console.log(error) }
  }


const driverService = {
    getAllDrivers,
  }


  export default driverService
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import subAdminService from './subAdminService'

const initialState = {
    subAdmins: [],
    subAdmin: {},
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
}

export const getAllSubAdmins = createAsyncThunk(
    'subAdmins/getAll',
    async (_, thunkAPI:any) => {
      try {
        const token = thunkAPI.getState().auth.user.access_token

        return await subAdminService.getAllSubAdmins(token)
      } catch (error: any) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
  
        return thunkAPI.rejectWithValue(message)
      }
    }  
)


// Create new area
export const createSubAdmin = createAsyncThunk(
  'subAdmin/create',
  async (subAdminData, thunkAPI: any) => {
    try {
      const token = thunkAPI.getState().auth.user.access_token
      return await subAdminService.createSubAdmin(subAdminData, token)
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()

      return thunkAPI.rejectWithValue(message)
    }
  }
)


// Create new area
export const deleteSubAdmin = createAsyncThunk(
  'subAdmin/delete',
  async (subAdminId: any, thunkAPI: any) => {
    try {
      const token = thunkAPI.getState().auth.user.access_token
      console.log(token)
      return await subAdminService.deleteSubAdmin(subAdminId, token)
    } catch (error: any) {
      const message =
        (error.response &&
          error.response.data &&
          error.response.data.message) ||
        error.message ||
        error.toString()

      return thunkAPI.rejectWithValue(message)
    }
  }
)



export const subAdminSlice = createSlice({
  name: 'subAdmin',
  initialState,
  reducers: {
      reset: (state) => initialState
  },
  extraReducers: (builder) => {
      builder.addCase(getAllSubAdmins.pending, (state) => {
          state.isLoading = true
        })
        .addCase(getAllSubAdmins.fulfilled, (state, action) => {
          state.isLoading = false
          state.isSuccess = true
          state.subAdmins = action.payload
        })
        .addCase(getAllSubAdmins.rejected, (state, action: any) => {
          state.isLoading = false
          state.isError = true
          state.message = action.payload
        })
        .addCase(createSubAdmin.pending, (state) => {
          state.isLoading = true
        })
        .addCase(createSubAdmin.fulfilled, (state) => {
          state.isLoading = false
          state.isSuccess = true
        })
        .addCase(createSubAdmin.rejected, (state, action: any) => {
          state.isLoading = false
          state.isError = true
          state.message = action.payload
        })
        .addCase(deleteSubAdmin.pending, (state) => {
          state.isLoading = true
        })
        .addCase(deleteSubAdmin.fulfilled, (state) => {
          state.isLoading = false
          state.isSuccess = true
        })
        .addCase(deleteSubAdmin.rejected, (state, action: any) => {
          state.isLoading = false
          state.isError = true
          state.message = action.payload
        })
  },
})

export const {reset} = subAdminSlice.actions

export default subAdminSlice.reducer
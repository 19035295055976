import React from 'react';

function LocationList({ locations, onSelectTrip, selectedTripId }) {
  return (
    <div className="w-1/4">
      {/* <h2 className="text-lg font-semibold mb-4">Trips</h2> */}
      <div className="border rounded overflow-auto max-h-96">
        <table className="min-w-full">
          <thead>
            <tr>
              <th className="px-6 py-3 bg-gray-200 text-gray-600 font-semibold uppercase text-sm">Areas</th>
            </tr>
          </thead>
          <tbody>
            {locations.map((location) => (
              <tr key={location.area.id} className={`cursor-pointer hover:bg-gray-100 ${location.id === selectedTripId ? 'bg-gray-200 border-2 border-gray-600' : 'border-b border-gray-300'}`} onClick={() => onSelectTrip(location)}>
                <td className="px-6 py-2 whitespace-nowrap text-blue-500 bg-white">{location.area.title}</td>
      
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default LocationList;

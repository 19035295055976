import React from 'react';
import ReactMapGL, { Marker } from 'react-map-gl';

function MapView({ longitude, latitude }) {
  const [viewport, setViewport] = React.useState({
    latitude,
    longitude,
    zoom: 10,
    // width:400,
    // height:400,
  });

  return (
    <div>
        {/* <div>Mapp Page</div> */}
    <ReactMapGL
      {...viewport} 
      mapStyle="mapbox://styles/mapbox/streets-v11"
      onViewportChange={setViewport}
      mapboxAccessToken="pk.eyJ1IjoiZGVzdGlueWFzIiwiYSI6ImNsdjR5bXBlZzAyOHIyaXBybmdtajU2cHkifQ.15HB43YnPwpVREoR8ZZkEw"
      style={{ width: '950px', height: '200px' }} 
    >
      <Marker longitude={longitude} latitude={latitude}>
        <div>P</div>
      </Marker>
    </ReactMapGL>
    {/* <div>Mapp Page</div> */}
    </div>
  );
}

export default MapView;

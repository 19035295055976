import axios from 'axios'

const API_URL = process.env.REACT_APP_URL || ' '

const getAllManifests = async (token: String) => {
  
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
    const response = await axios.get('https://api.travelreg.com.ng/emanifest/admin?skip=0&limit=1000&order_by=DATE_CREATED&order_direction=DESC',config)
    return response.data
  }


const manifestService = {
    getAllManifests,
  }


  export default manifestService
import axios from 'axios'

const API_URL = process.env.REACT_APP_URL || ' '

// const create An Area 
const createSubAdmin = async (userData: any, token: String) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.post(API_URL + 'users', userData, config)
  return response.data
}

// Get user restaurants
const getAllSubAdmins = async (token: String) => {
  
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
    const response = await axios.get(API_URL + 'users/sub-admins?skip=0&limit=10000',config)
    return response.data
  }

// Delete An Area
const deleteSubAdmin = async (userId: any, token: String) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  console.log(userId)
  const response = await axios.delete(
    API_URL + 'user/'+ userId, config)
  return response.data
}



const subAdminService = {
  getAllSubAdmins,
    createSubAdmin,
    deleteSubAdmin
  }


  export default subAdminService
import React, { useState } from 'react';
import { Menu } from '@headlessui/react';
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableFooter from "@mui/material/TableFooter";
import TableHead  from '@mui/material/TableHead';
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TablePaginationActions from "../components/TablePaginationActions";
import TablePagination from "@mui/material/TablePagination";
import {
    MagnifyingGlassIcon,
    ChevronDownIcon
  } from '@heroicons/react/24/outline'
import { Link } from 'react-router-dom'
import CreateSubAdmin from '../components/modals/CreateSubAdmin';
import {useNavigate} from "react-router-dom"
import {  useSelector } from 'react-redux'
import { getAllSubAdmins } from '../slices/subAdmin/SubAdminSlice';
import { getAllParkAdmins } from '../slices/parkAdmin/parkAdminSlice';
import { logout,reset } from "../slices/auth/authSlice"
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import Spinner from 'react-spinkit'
import TripList from '../components/TripList';
import TripDetails from '../components/TripDetails';
import { getAllManifests } from '../slices/manifest/manifestSlice';
import axios from 'axios';

function Dash () {

  const [progressData, setProgressData] = useState({}); 

  const navigate = useNavigate()
  const [modal, setModal] = useState(false)

  const {manifests, isLoading, isSuccess} = useSelector(
    (state) => state.manifests)
  console.log(manifests)
  
  const {user} = useSelector((state) => state.auth)
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    const token = user.access_token
    const config = {
      headers: { 
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data"
      }
     };

    const userP = {
        name: user.fullName,
        imageUrl:  `https://api.travelreg.com.ng/users/${user.id}/download-selfie`
         // Replace with the actual image URL
      };

      useEffect(() => {
        const fetchData = async () => {
          try {
            // Make API call to fetch progress data
            const response = await axios.get('https://api.travelreg.com.ng/emanifest/metrics',config);
            // Assuming the response data is an object with key-value pairs
            setProgressData(response.data);
          } catch (error) {
            console.log('Error fetching progress data:', error);
          }
        };
    
        fetchData();
      }, []);
    
    
      // Function to handle logout
      const handleLogout = () => {
        dispatch(logout())
        dispatch(reset())
        navigate('/')
        console.log("Logging out")
      };
      const [isOpen, setIsOpen] = useState(false);

      const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - manifests.length) : 0;

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const trips = manifests && manifests.manifests ? manifests.manifests.filter((manifest) => manifest.status === "completed") : [];
  console.log(trips)

        const dispatch = useDispatch()

    
       
        useEffect(() => {
          dispatch(getAllManifests())
        }, [dispatch])

  const [selectedTrip, setSelectedTrip] = useState(trips[0]);
  const handleSelectTrip = (trip) => {
    setSelectedTrip(trip);
  };
        

      if (isLoading) {
        return (
        <div className="grid place-items-center h-full w-full">
        <Spinner 
        name="ball-spin-fade-loader"
        color="blue"
        fadeIn="none"
        className="flex-col pt-32 grid place-items-center items-center  absolute top-1/2 justify-center  h-screen"
        />
        </div>
        )
      }
  
      
  return (
    <div className="bg-gray-100 h-screen ">
        <div className="bg-white flex justify-between items-center px-6 py-4">
            <div>
                {/* <p>cgfgffvff</p> */}
                <form className="flex w-full lg:ml-0" action="#" method="GET">
                          <label htmlFor="search-field" className="sr-only">
                            Search
                          </label>
                          <div className="relative w-full mx-auto left-6 mt-2  text-gray-400 focus-within:text-gray-600">
                            <div className="pointer-events-none absolute inset-y-0  left-2 flex items-center">
                              <MagnifyingGlassIcon className="h-6 w-6 pl-2" aria-hidden="true" />
                            </div>
                            <input
                              id="search-field"
                              className="block h-full w-30 border-gray rounded-lg py-2 pl-8  placeholder-gray-400 focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-0 sm:text-sm px-4 bg-white-100 text-white"
                              placeholder="Search"
                              type="text"
                              name="search"
                            />
                          </div>
                          </form>
            </div>
            <div className="relative">
        <Menu>
          {({ open }) => (
            <>
              <Menu.Button className="flex items-center">
                <img
                  className="h-8 w-8 rounded-full"
                  src={userP.imageUrl}
                  alt={userP.name}
                />
                <span className="ml-2 text-gray-800 cursor-pointer">{userP.name}</span>
                <ChevronDownIcon className="h-5 w-5 ml-1 text-gray-400" />
              </Menu.Button>
              <Menu.Items className={`absolute right-0 mt-2 w-48 bg-white shadow-lg rounded-md ${open ? 'block' : 'hidden'}`}>
                <Menu.Item>
                  {({ active }) => (
                    <button
                      className={`w-full py-2 px-4 text-sm text-gray-800 ${active ? 'bg-gray-100' : ''}`}
                      onClick={handleLogout}
                    >
                      Logout
                    </button>
                  )}
                </Menu.Item>
                {/* Add more Menu.Item components for additional options */}
              </Menu.Items>
            </>
          )}
        </Menu>
      </div>   
      </div>
      <div className="flex justify-between">
      <p className="font-bold text-left">Analytics</p>
      {/* <p className="text-right">{new Date().toLocaleDateString()}</p> */}
      </div>
      <div className="flex justify-between items-center px-6 py-4">
        <div>
          {/* <p>Analytics</p> */}
        </div>

    <div className="flex flex-col md:flex-row mt-2 h-100 w-full">

    <div className="box bg-white m-2 p-4 w-full md:w-1/4 h-40">
    <h1 className='mt-5 font-bold text-2xl'>{progressData.currrentTrips}</h1>
      <span>Current Trips</span>
      </div>
      <div className="box bg-white m-2 p-4 w-full md:w-1/4 h-40">
      <h1 className='mt-5 font-bold text-2xl'>{progressData.pendingTrips}</h1>
      <span>Pending Trips</span>
      </div>
      <div className="box bg-white m-2 p-4 w-full md:w-1/4 h-40">
      <h1 className='mt-5 font-bold text-2xl'>{progressData.completedTrips}</h1>
      <span>Completed Trips</span>
      </div>
      <div className="box bg-white m-2 p-4 w-full md:w-1/4 h-40">
      <h1 className='mt-5 font-bold text-2xl'>{progressData.numberOfPassenger}</h1>
      <span>Passengers</span>
      </div>
    </div>
    </div>
    <div className="flex justify-between">
      <p className="font-bold text-left">Completed trips</p>
      {/* <p className="text-right">{new Date().toLocaleDateString()}</p> */}
      </div>
    {/* <div className=" relative mt-2 py-2 w-full">
        <img src={dash} alt="Map" className="inset-0 w-full h-auto" />
      </div>
                      */}
                      {/* <div className="flex"> */}

   <div className="flex">
      <TripList trips={trips} onSelectTrip={handleSelectTrip} />
      <div style={{ height: '400px', overflowY: 'auto' }}>
      <TripDetails trip={selectedTrip} />
      </div>
    </div>
 
{/* </div> */}
</div>

  );
};

export default Dash;

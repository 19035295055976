import React from 'react';

function TripList({ trips, onSelectTrip, selectedTripId }) {
  return (
    <div className="w-1/4">
      {/* <h2 className="text-lg font-semibold mb-4">Trips</h2> */}
      <div className="border rounded overflow-auto max-h-96">
        <table className="min-w-full">
          <thead>
            <tr>
              <th className="px-6 py-3 bg-gray-200 text-gray-600 font-semibold uppercase text-sm">ID</th>
              <th className="px-6 py-3 bg-gray-200 text-gray-600 font-semibold uppercase text-sm">Date</th>
              <th className="px-6 py-3 bg-gray-200 text-gray-600 font-semibold uppercase text-sm">Status</th>
            </tr>
          </thead>
          <tbody>
            {trips.map((trip) => (
              <tr key={trip.id} className={`cursor-pointer hover:bg-gray-100 ${trip.id === selectedTripId ? 'bg-gray-200 border-2 border-gray-600' : 'border-b border-gray-300'}`} onClick={() => onSelectTrip(trip)}>
                <td className="px-6 py-4 whitespace-nowrap text-blue-500 bg-white">{trip.id}</td>
                <td className="px-6 py-4 whitespace-nowrap bg-white">{trip.createdAt.substring(0, 10)
}</td>
                <td className="px-6 py-4 whitespace-nowrap text-green-500 bg-white">{trip.status
}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
}

export default TripList;

import axios from 'axios'

const API_URL = process.env.REACT_APP_URL

// Login user
const login = async (userData: any) => {
  const response = await axios.post(API_URL + 'log-in-admin', userData)

  if (response.data) {
    localStorage.setItem('user', JSON.stringify(response.data))
  }
  return response.data
}

// Logout user
const logout = () => localStorage.removeItem('user')

const getLoggedInUser = async (id: String, token: String) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const response = await axios.get(API_URL + 'users' + id, config )

  if (response.data) {
    localStorage.setItem('userInfo', JSON.stringify(response.data))
  }
  return response.data
}

const authService = {
  logout,
  login,
  getLoggedInUser
}

export default authService

import axios from 'axios'

const API_URL = process.env.REACT_APP_URL || ' '

// Get user restaurants
const getAllAreas = async () => {
  
    const response = await axios.get(API_URL + 'areas?skip=0&limit=1000')
  
    return response.data
  }

// const create An Area 
const createAnArea = async (areaData: any, token: String) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await axios.post(API_URL + 'areas', areaData, config)

  return response.data
}

// Delete An Area
const deleteAnArea = async (areaId: any, token: String) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  console.log(areaId)
  const response = await axios.delete(
    API_URL + 'areas/'+ areaId, config)
 
  return response.data
}



const areaService = {
    getAllAreas,
    createAnArea,
    deleteAnArea
  }


  export default areaService
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import parkAdminService from './parkAdminService'

const initialState = {
    parkAdmins: [],
    parkAdmin: {},
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
}

export const getAllParkAdmins = createAsyncThunk(
    'parkAdmins/getAll',
    async (_, thunkAPI:any) => {
      try {
        const token = thunkAPI.getState().auth.user.access_token

        return await parkAdminService.getAllParkAdmins(token)
      } catch (error: any) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
  
        return thunkAPI.rejectWithValue(message)
      }
    }  
)



export const parkAdminSlice = createSlice({
  name: 'parkAdmin',
  initialState,
  reducers: {
      reset: (state) => initialState
  },
  extraReducers: (builder) => {
      builder.addCase(getAllParkAdmins.pending, (state) => {
          state.isLoading = true
        })
        .addCase(getAllParkAdmins.fulfilled, (state, action) => {
          state.isLoading = false
          state.isSuccess = true
          state.parkAdmins = action.payload
        })
        .addCase(getAllParkAdmins.rejected, (state, action: any) => {
          state.isLoading = false
          state.isError = true
          state.message = action.payload
        })
  },
})

export const {reset} = parkAdminSlice.actions

export default parkAdminSlice.reducer
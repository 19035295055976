import {createSlice, createAsyncThunk} from '@reduxjs/toolkit'
import manifestService from './manifestService'

const initialState = {
    manifests: [],
    manifest: {},
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: '',
}

export const getAllManifests = createAsyncThunk(
    'manifests/getAll',
    async (_, thunkAPI:any) => {
      try {
        const token = thunkAPI.getState().auth.user.access_token

        return await manifestService.getAllManifests(token)
      } catch (error: any) {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString()
  
        return thunkAPI.rejectWithValue(message)
      }
    }  
)



export const manifestSlice = createSlice({
  name: 'manifest',
  initialState,
  reducers: {
      reset: (state) => initialState
  },
  extraReducers: (builder) => {
      builder.addCase(getAllManifests.pending, (state) => {
          state.isLoading = true
        })
        .addCase(getAllManifests.fulfilled, (state, action) => {
          state.isLoading = false
          state.isSuccess = true
          state.manifests = action.payload
        })
        .addCase(getAllManifests.rejected, (state, action: any) => {
          state.isLoading = false
          state.isError = true
          state.message = action.payload
        })
  },
})

export const {reset} = manifestSlice.actions

export default manifestSlice.reducer
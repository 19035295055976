import { useEffect, useState } from 'react'
import {  useSelector,useDispatch } from 'react-redux'
import axios from "axios";
import {Link, useNavigate, useParams} from "react-router-dom"


function UserDetails() {


  const dispatch = useDispatch()
  const navigate = useNavigate()
  const {parkAdmins, isLoading, message} = useSelector((state) => state.parkAdmins)
  const {user} = useSelector((state) => state.auth)
   const {parkAdminId} = useParams();
  const parkAdmin = parkAdmins.find((parkAdmin) => parkAdmin.id === parkAdminId)

  const token = user.access_token

  const {phoneNumber, nin, fullName, gender, email, park, homeAddress, selfieFile, id} = parkAdmin

  const selfie = `https://api.travelreg.com.ng/users/${id}/download-selfie`


const config = {
  headers: { 
    Authorization: `Bearer ${token}`,
    "Content-Type": "multipart/form-data"
  }
 };



  return (
    <div className="relative z-0 h-screen overflow-hidden bg-trans-back">
    <div className='mt-6'>
    <Link to='/dashboard/park' className='rounded-md border m-4 px-2 py-2 bg-white'>Back </Link>
        <main className="relative z-0  overflow-y-auto focus:outline-none mt-4 grid grid-cols-2">
          {/* Start main area*/}
          <div className=' mx-6 bg-white p-4 rounded-2xl grid space-y-4 text-indigo-600'>
          <h1>Full Name: {fullName}</h1>
          <h1> Phone Number: {phoneNumber}</h1>
          <h1>NIN: {nin}</h1>
          <h1> Age: {homeAddress}</h1>
          <h1> Home Address: {homeAddress}</h1>
          <h1> Email: {email} </h1>
          <h1> Park: {park? park.title:""} </h1>

          {/* <h1>Created By: {new Date(subAdmin.createdAt).toLocaleString('en-US')}</h1> */}
          
          </div>       
                
{parkAdmin.selfieFile &&
         <div className="  mt-1 py-2 h-500 w-full">
         <img src={selfie} alt="Map" className="inset-0 w-full h-full object-cover" style={{ maxHeight: '400px' }} />
       </div>

          }


         
         

        </main>

    </div>
  
</div>
  )
}

export default UserDetails
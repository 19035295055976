import React from 'react';
import MapView from '../components/MapView'
import PassengerTable from '../components/passengerTable'; // Import the PassengerTable component


function TripDetails({ trip }) {
  if (!trip) {
    return (
      <div className="w-2/3" id="details">
        <div className="trip-details">
          <p>Select a trip to view details</p>
        </div>
      </div>
    );
  }
  
// Access the "from" and "to" properties of the object
const fromValue = trip.route["from"]
const toValue = trip.route["to"];

  return (
    <div className="p-4 w-950"   id="details">
      <div className="trip-details bg-white">
      <div className="flex">
      <div className="w-full md:w-1/3 p-1">
        <h2 className="font-bold text-sm text-gray-400">Driver</h2>
        <p className="font-bold text-sm">{trip.driverName}</p>
      </div>
      <div className="w-full md:w-1/3 p-1">
        <h2 className="font-bold text-sm text-gray-400">Current Location</h2>
        <p className="font-bold text-sm">{trip.endCoordinates.latitude}-{trip.endCoordinates.longitude}</p>
      </div>
      <div className="w-full md:w-1/3 p-1">
        <h2 className="font-bold text-sm text-gray-400">Number of Passengers</h2>
        <p className="font-bold text-sm">{trip.numberOfPassengers}</p>
      </div>
    </div>
      <div className="flex">
      <div className="w-full md:w-1/3 p-1">
        <h2 className="font-bold text-sm text-gray-400">Plate Number</h2>
        <p className="font-bold text-sm">{trip.plateNumber}</p>
      </div>
      <div className="w-full md:w-1/3 p-1">
        <h2 className="font-bold text-sm text-gray-400">Travel Time(mins)</h2>
        <p className="font-bold text-sm">{trip.durationInMinutes}</p>
      </div>
      <div className="w-full md:w-1/3 p-1">
        <h2 className="font-bold text-sm text-gray-400">Route</h2>
        <p className="font-bold text-sm">{fromValue}-{toValue}</p>
      </div>
    </div>
      </div>
      <div>
      <MapView  key={`${trip.startCoordinates
.latitude}-${trip.startCoordinates
    .longitude}`} longitude={trip.startCoordinates
        .longitude} latitude={trip.startCoordinates
            .latitude} />
      </div>

      <div className='overflow-auto'>
        <PassengerTable passengers={trip.passengers} />
      </div>
    </div>
  );
}

export default TripDetails;

import React from 'react';
import ResponsiveTable from './responsiveTable'; // Import the ResponsiveTable component

function PassengerTable({ passengers }) {
  const columns = [
    {
      Header: 'Passenger',
      accessor: 'name',
    },
    {
      Header: 'Age',
      accessor: 'age',
    },
    {
      Header: 'Sex',
      accessor: 'sex',
    },
    {
        Header: 'Marital Status',
        accessor: 'maritalStatus',
      },
      {
        Header: 'Phone Number',
        accessor: 'phoneNumber',
      },
      {
        Header: 'Address',
        accessor: 'address',
      },
    {
      Header: 'Email',
      accessor: 'email',
    },
    
    {
      Header: 'N.O.K',
      accessor: 'nextOfKinPhoneNumber',
    },
  ];

  return <ResponsiveTable columns={columns} data={passengers} />;
}

export default PassengerTable;

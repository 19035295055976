import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import SubAdmin from './pages/SubAdmin';
import PrivateRoute from './components/PrivateRoute';
import Location from './pages/Location';
import User from './pages/User';
import ParkAdmin from './pages/ParkAdmin';
import Dash from './pages/Dash';
import UserDetails from './pages/UserDetails';
import Driver from './pages/Driver';
import Vehicle from './pages/Vehicle';
import Tracking from './pages/Tracking';

function App() {
  return (
    <>
    <Router>
    <div className="h-full min-h-full bg-white">
      <Routes>
      <Route path='/dashboard/*' element ={<Dashboard />}>
        <Route path='' element ={<PrivateRoute />}>
          <Route path='' element={<Dash/>}/>
              </Route>
          <Route path='sub' element ={<PrivateRoute />}>
            <Route path='' element={<SubAdmin />}/> 
          </Route>
          <Route path='park' element ={<PrivateRoute />}>
            <Route path='' element={<ParkAdmin />}/> 
          </Route>
          <Route path='driver' element ={<PrivateRoute />}>
            <Route path='' element={<Driver />}/> 
          </Route>
          <Route path='vehicle' element ={<PrivateRoute />}>
            <Route path='' element={<Vehicle />}/> 
          </Route>
          <Route path='tracking' element ={<PrivateRoute />}>
            <Route path='' element={<Tracking />}/> 
          </Route>
          <Route path='sub/:subAdminId' element ={<PrivateRoute />}>
            <Route path='' element={<User />}/> 
          </Route>
          <Route path='park/:parkAdminId' element ={<PrivateRoute />}>
            <Route path='' element={<UserDetails />}/> 
          </Route>
          <Route path='location' element ={<PrivateRoute />}>
            <Route path='' element={<Location />}/> 
          </Route>

      </Route>
      {/* </Route> */}
      

      <Route path='/' element={<Login />}/>
      </Routes>
      </div>
    </Router>
    <ToastContainer />
    </>
  );
}

export default App;
